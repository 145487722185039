.windowfilmpage .bg-image-section {
    background-image: url(Windowfilmbg.png);
}
  
  
  
  .windowfilmpage .section-content {
    padding: 3.5vw 5.5vw 3.5vw 3.5vw;
    height:auto;
    background-color: #0e1b39;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }
  
  .windowfilmpage .left-content {
    margin: 1vw 0 0 3.3vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .windowfilmpage .right-content {
    position: absolute;
    top: 0;
    right: 0;
  
  }
  
  .windowfilmpage .right-content img {
    margin: 6vw 8.3vw 0rem 0rem;
    width: 35vw;
    height: auto;
  }
  
  
  
  
  
  
  
  .windowfilmpage .points {
    margin: 2vw 0 0 0;
    text-align: justify;
   
    font-family: "Montserrat";
    color: #f6f7f8;
   
    font-weight: 300;
  }
  
  .windowfilmpage .points li {
   padding: 0px 3vw 0px 0px;
  }
  .windowfilmpage .table{
    height: auto;
  }
  
 
  
  .windowfilmpage .table img {
    width: 82.5vw;
    height: auto;
  }

  .windowfilmpage .end{
    background-color: #0e1b39;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vw;
    height:33vh;
  }
  .windowfilmpage .end img {
    width: 83vw;
    height: auto;
  }
  
  @media (max-width:441px){

    .windowfilmpage .points {
      margin: 2vw 0 0 -5.5vw;
    }
    .windowfilmpage .table img {
      width: 85vw;
      height: auto;
      
    }
    .windowfilmpage .right-content {
      position: relative;
      
    
    }
    .windowfilmpage .right-content img {
      width: 80vw;
      height: auto;
      margin: 5vw 0vw 0rem 8vw;
      
    }
    .windowfilmpage .end  {
      width: 95vw;
      height:10vh;
      transform: translateX(2.5vw);
     
    }
    }
    @media (min-width:441px) and (max-width:766px){
      .windowfilmpage  .bg-image-section {
        height:70vh;
        width: auto;
      }
      .midle {
        font-size: 7.5vw;
      }
      .windowfilmpage .points {
        margin: 2vw 0 0 -1.5vw;
      }
      .windowfilmpage .table img {
        width: 85vw;
        height: auto;
        
      }
      .windowfilmpage .right-content {
        position: relative;
        
      
      }
      .windowfilmpage .right-content img {
        width: 80vw;
        height: auto;
        margin: 5vw 0vw 0rem 8vw;
      }
    }
    @media (min-width:441px) and (max-width:679px){

      .windowfilmpage  .bg-image-section {
        height:48vh;
        width: auto;
      }
    }
 
  