.section-container {
    background-image: url(partnersbg.png); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 49.7vh; 
    display: grid;
    align-items: center;
    justify-content: start;
  }
  
  .content {
    width: 55vw;
    margin-left: 4.4vw;
    margin-top: 2vw;
    
  }
  
  .small-heading {
    font-size: 1.7vw;
    font-weight: 800;
   
    font-family: "Montserrat";
    color: #b0ad9e;
    letter-spacing: 1px;
  }
  
  .big-heading {
    font-family: "Montserrat";
    font-size: 3.7vw;
    font-weight: 800;
    color: #ffc13b;
  }
  
  .logos {
  margin-bottom: 5vw;
  row-gap: 3.8vw;
    
  }
  .logo1{
width: 10vw;
  }
  .logo2{
width: 10vw;
  }
  .logo3{
width: 10vw;
  }
  .logo4{
width: 10vw;
  }
  .logo5{
width: 5vw;
margin:0 0 0 2vw
  }
  .logo6{
width: 10vw;
  }
  .section-container:hover .logos{
    animation:fadeInRight;
    animation-duration: 2s;
  }
  .section-container:hover .big-heading{
    animation:fadeInLeft;
    animation-duration: 2s;
  }
  
  
  
  @media (max-width:441px){
    .section-container{
      height: fit-content;
     
    }
    .small-heading {
      font-size: 4.5vw;
    }
    .big-heading {
      font-size:6.5vw;
    }
    
  .logo1{
width: 18vw;
  }
  .logo2{
width: 18vw;
  }
  .logo3{
width: 18vw;
  }
  .logo4{
width: 18vw;
  }
  .logo5{
width: 9vw;
  }
  .logo6{
width: 18vw;
  }
  }
  @media (min-width:441px) and  (max-width:766px){
    .section-container{
      height: fit-content;
     
    }
    .small-heading {
      font-size: 4.5vw;
    }
    .big-heading {
      font-size:6.5vw;
    }
    
  .logo1{
width: 18vw;
  }
  .logo2{
width: 18vw;
  }
  .logo3{
width: 18vw;
  }
  .logo4{
width: 18vw;
  }
  .logo5{
width: 9vw;
  }
  .logo6{
width: 18vw;
  }
  }