.outer-section {
    background-image: url('2\ About\ us\ bg\ image.png'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 50.3vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .inner-section {
    background-color: rgba(14, 27, 57, 0.9);
    color: white;
    width: 100%; 
    height: 38vh;
    display: grid;
    align-content: center;
    text-align: center;
  }
  .inner-section h1{
    font-family: "Montserrat";
    font-weight: 900;
    color: #b0ad9e;
    font-size: 3.2vw;
    letter-spacing: 0.2vw;
 
  }
  .inner-section p{
    font-size: 1.474vw;
    padding-left: 3vw;
    padding-right: 3vw;
   
    font-family: "Montserrat";
    text-align: justify;
  }

  @media (max-width:441px){
    .outer-section{
      height: 60.1vh;
    }
    .inner-section{
      height:auto;
    }
    .inner-section h1{
      font-size: 6.2vw;
    }
    .inner-section p{
      font-size: 3.5vw;
    }
  }
  @media  (min-width:441px) and (max-width:766px){
    .outer-section{
      height: 70.1vh;
    }
    .inner-section{
      height:auto;
    }
    .inner-section h1{
      font-size: 4.8vw;
    }
    .inner-section p{
      font-size: 2.154vw;
    }
  }