
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.front {
  background: url('header.png') no-repeat center center;
  background-size: cover;
  background-position: center;
 background-repeat: no-repeat;
  height: 76.5vh; 
  object-fit: cover;
  width: 100vw;
  position: relative;
  overflow: hidden;
  
}
.front::after{
  
}

.overlay {
 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  background: linear-gradient(
    to bottom,
    #e9e9e9 10%,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0) 75.7%,
    rgba(12, 21, 47, 0.75) 93.7%,
    rgba(12, 21, 47, 1) 100%
    
  );
 
  z-index: 1;
 
}



.overlay h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 3.7vw; 
  margin: 0vw 0vw 30vw 0vw;
  letter-spacing:0.5vw;
  animation: fadeInDown 10s infinite ;
  
  color: #0e0e0e;
 
}




@media (max-width: 1200px) {
  .overlay h1 {
    font-size: 3.8vw;
  }
}

@media (max-width: 992px) {
  .overlay h1 {
    font-size: 4.5vw;
  }
}

@media (max-width: 768px) {
  .overlay h1 {
    font-size: 5.5vw;
    
  }
  
}

@media (max-width: 576px) {
  .overlay h1 {
    font-size: 6.5vw;
  }
}

@media (max-width: 441px) {
  .front{
    height: 55vh;
   
  }
  
  .overlay h1 {
    font-size: 5vw;
    margin: 0vw 0vw 45vw 0vw;

  }
  
  
  
  
}
@media (min-width: 441px) and (max-width: 766px) {
  
  
  .overlay h1 {
    font-size: 3.7vw;
    margin: 0vw 0vw 40vw 0vw;

  }
  
  
  
  
}


@media  (min-width:1800px){
  .overlay h1{
    margin: 0rem 0rem 28vw 0rem;
  }
  
    
    }
  