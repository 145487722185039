.info-img {
  height:0vh;
  width: 100%;
  object-fit: cover;
  line-height: 0;
  opacity: 0;
  transition: all 1s ease;

}
.info-img-effect {
  height: 50vh;
  opacity: 1;
  transform: translateY(0);
  line-height: 13px;
}
.details {
  height: 50vh;
  padding: 0vw 4.5vw 0vw 4.5vw;

}


.details h3 {
  font-size: 1.7vw;
  font-weight: 800;
  font-family: "Montserrat";
  color: #b0ad9e;
}

.details h2 {
  font-family: "Montserrat";
  font-weight: 800;
  color: #ffc13c;
  font-size: 3.5vw;
  transition: all ease-in-out;
  animation-duration: 0.8s;
}

.details p {
  text-align: justify;
  cursor: default;
  font-family: "Montserrat";
  color: #f6f7f8;
  font-size: 1.4vw;
  font-weight: 400;
}

.line-container {
  display: flex;
  align-items: center;
  margin: -1.9vw 0 0 0;
}

.liner {
  height: 1px;
  width: 33.5vw;
  background-color: #f6f7f8;
  margin: 0.8vw 0 0 0;
}

.text a {
  margin-left: 0.5vw;
  font-family: "Montserrat";
  font-weight: 600;
  text-decoration: none;
  color: #ffc13c;
  letter-spacing: 0.1vw;
  text-transform: uppercase;
  /* font-size: 0.7vw; */
  font-size: 8px;
}

@media (max-width: 441px) {
  .info-img {
    height: auto;
  }
  .details{
    height: auto;
    padding: 3vw 7vw 3vw 7vw;
  }
  .details h3 {
    font-size: 4.5vw;
  }
  .details h2 {
    font-size: 7.5vw;
  }
  .details p {
    font-size: 3.5vw;
  }
  .liner{
    width:70vw;
  }
}
@media (min-width: 441px) and (max-width: 766px) {
  .info-img {
    height: auto;
  }
  .details{
    height: auto;
    padding: 3vw 7vw 3vw 7vw;
  }
  .details h3 {
    font-size: 4.5vw;
  }
  .details h2 {
    font-size: 7.5vw;
  }
  .details p {
    font-size: 3.3vw;
  }
  .liner{
    width:70vw;
  }
}
