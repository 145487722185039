.mattepage .bg-image-section {
    background-image: url(Mattebg.png);
  }
  
  
  .mattepage .section-content {
    padding: 3.5vw 5.5vw 3.5vw 3.5vw;
    height: auto;
    background-color: #0e1b39;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }
  
  .mattepage .left-content {
    margin: 1vw 0 0 3.3vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .mattepage .right-content {
    position: absolute;
    top: 0;
    right: 0;
  
  }
  
  .mattepage .right-content img {
    margin: 6vw 8.3vw 0rem 0rem;
    width: 35vw;
    height: auto;
  }
  
  
  
  
  .mattepage .points {
    margin: 2vw 0 0 0;
    text-align: justify;
    font-family: "Montserrat";
    color: #f6f7f8;
   
    font-weight: 300;
  }
  
  .mattepage .points li {
    
    padding: 0px 3vw 0px 0px;
  }
  
  
  
  .mattepage .table img {
    width: 82.5vw;
    height: auto;
  }
  


  @media (max-width:441px){

    
    .mattepage .points {
      margin: 2vw 0 0 -5.5vw;
    }
    .mattepage .table img {
      width: 82.5vw;
      height: auto;
    }
    .mattepage .right-content {
      position: relative;
      
    
    }
    .mattepage .right-content img {
      width: 80vw;
      height: auto;
      margin: 5vw 0vw 0rem 8vw;
    }
    
    
    }
    @media (min-width:441px) and (max-width:766px){
      .mattepage .bg-image-section {
        height:75vh;
        width: auto;
      }
      .midle {
        font-size: 7.5vw;
      }
      .mattepage .points {
        margin: 2vw 0 0 -1.5vw;
      }
      .mattepage .table img {
        width: 82.5vw;
        height: auto;
      }
      .mattepage .right-content {
        position: relative;
        
      
      }
      .mattepage .right-content img {
        width: 80vw;
        height: auto;
        margin: 5vw 0vw 0rem 8vw;
      }
      
    }
    @media (min-width:441px) and (max-width:614px){
      .mattepage .bg-image-section {
        height:45vh;
        width: auto;
      }}
    @media (min-width:615px) and (max-width:871px){
      .mattepage .bg-image-section {
        height:75vh;
        width: auto;
      }}