.navbar {
  background-color: #e9e9e9;
  height: 23.5vh;
}
.navbar-toggler{

 color: #e9e9e9;
}
.navbar .nav-link {
  font-family: "Montserrat";
  font-size: 1vw;

  font-weight: 900;
  color: #121f3f;
}
.navbar .container-fluid{
margin-left: 4vw;
margin-top: 2vw;
}

.navbarlogo {
 
 
 width:78px ;
 height: 91px;
  
 
}

.navbar-nav {
 column-gap: 4vw;
  margin-left: 30vw;
}



.dropdown-item {
  font-family: "Montserrat";
  font-size: 1vw;
  font-weight: 900;
}

.dropdown-item:hover {
  background-color: #77797d;
}
.contact{
 
  padding: 0px 1vw 0px 1vw;

  letter-spacing: 0.4px;
  transform: translateY(8px);
}


@media (max-width: 991px) {
  .navbar-nav{
    margin-left: 0;
  }
  .contact{
    width: 11vw;
    
  }

  .navbar-toggler {
    margin-right: 15px;
  }
}





@media (max-width:1051px){
  .navbar{
    height: auto;
  }
}



@media  (min-width:1730px) {
   .navbarlogo{
    height: 150px;
    width: 127px;
  } 

  
}
@media  (max-width:441px) {
   .navbarlogo{
    height: 70px;
    width: 60px;
  } 
.navbar .nav-link{
  font-size: 3vw;
}
.dropdown-menu{
  width: 1vw;
}
.dropdown-item{
  font-size: 3vw;
 
  
}
.contact{
 
 width: 25vw;
}
}
@media  (min-width:441px) and (max-width:766px){
   
.navbar .nav-link{
  font-size: 2vw;
}
.dropdown-menu{
  width: 0.8vw;
}
.dropdown-item{
  font-size: 2vw;
 
  
}
.contact{
 
 width: 17vw;
}
}