.wholefooter{
 display: flex;
 flex-direction: column;
 background-color: #08112b;
}
.footerimg{
 
    position: relative;
    width: auto;
    height: auto;
   
   
}
.footerimg img{
  width: 100vw;
  height: auto;
}
.footerimg::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 82%, rgba(7, 17, 44, 0.9) 96.7%, rgba(7, 17, 44, 0.5) 93.7%, rgba(7, 17, 44, 1) 55%);
}

 


.fcontainer {

    text-align: center;
    height: fit-content;
    position: relative;
    background-color: #07112c;
  }
  
  .fheading {
    font-family: Montserrat ;
    font-size: 0.8vw;
    font-weight: 800;
    color: #ffc13b;
    transform: translateY(-2.3vw);
    letter-spacing: 0.1vw;
  }
  
  .fline {
    display: inline-block;
    position: absolute;
    top: -2vw;
    width: 40vw;
    height: 1px;
    background-color:white;
  }
  
  .left {
    display: inline-block;
    left: 5.5vw;
   
  }
  
  .right {
    display: inline-block;
    right:5.5vw;
    
    
  }
  .number a{
    text-decoration: none;
  }

  .number h2{
    display: inline-block;
    letter-spacing:0.3vw;
   
    color: white;
   
    font-size: 0.8vw;
    font-family: mynewfont2;
  }
  .brand{
    padding: 8px 20px 0px 41px;
    display: inline-block;
    letter-spacing: 0.4vw;
    color: white;
    transform: translateY(2.5vw);
    font-weight: 800;
    font-family: mynewfont2;
    border: 2px solid white;
  }
  .brand h3{
    font-size: 0.9vw;
  }
  
  


  .form-container {
    transform: translateY(6vw);
    display: flex;
    height: 25vh;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.3vw;
    background-color: #08112b;
  }
  .mailbox{
    width: 28vw;
    height: 5vh;
    background-color: white;
    padding: 0px 0px 4px 16px;
    box-sizing: border-box;
  }
  
  .input-name-email {
    width: 16.5vw;
    height: 5vh;
    background-color: white;
    padding: 0px 0px 4px 16px;
    box-sizing: border-box;
    
    
  }
  
  
  

  .input-name-email::placeholder,.mailbox::placeholder,
  .input-message::placeholder {
    font-size: 10.5px;
    font-family: mynewfont2;
    
    color: #9d9a8d; 
    letter-spacing: 2px;
    
}

  
  .submit-button {
    width: 23vw;
    height: 5vh;
    background-color:#ffc13b;
    
    color: white;
    
    font-size: 0.75vw;
    font-family: mynewfont2;
    
    letter-spacing: 0.3vw;
  }
  .lastfooter{
    
   
    background-color: #08112b;

  }

  .footer-end {

    height: auto;
    transform: translateY(4vw);
    order: 3;
    background-color: #07112c;
   
    
  }
  .copyright{
    padding: 0rem 0vw 0vw 8vw;
    color: #ffc13b;
    font-size: 1.2vw;
   
  }
  
  .social-icons {
   
    padding: 0rem 2.9vw 2vw 0vw;
    
   

  }
  
  
  .social-icons a {
    font-size: 1.7vw;
    margin: 0rem 5vw 0rem 0rem;
    color: #ffc13b;
  }
  .social-icons a:hover{
    
    color: white;
  }
  
  
  
  @media (max-width: 600px) {
    .form-container {
      flex-direction: column;
    }
  
    .input-name-email,.mailbox,
    .input-message,
    .submit-button {
      width: 100%;
    }
  }
  @media (max-width:441px) {
    .footerimg{
      transform: translateY(5vw);
    }
.fheading{
  font-size: 2vw;
  transform: translateY(0);
}
.fline{
  width: 35vw;
  top: 1vw;
}
.number h2{
font-size: 2vw;
}
.brand h3{
  font-size: 2.2vw;
}
.copyright{
  font-size:2vw ;
}
.social-icons a{
  font-size: 3vw;
}
.submit-button{
  font-size: 3vw;
}

}
  @media (min-width:441px) and (max-width:766px){
    .footerimg{
      transform: translateY(5vw);
    }
.fheading{
  font-size: 2vw;
  transform: translateY(0);
}
.fline{
  width: 35vw;
  top: 1vw;
}
.number h2{
font-size: 2vw;
}
.brand h3{
  font-size: 2.2vw;
}
.copyright{
  font-size:2vw ;
}
.social-icons a{
  font-size: 3vw;
}
.submit-button{
  font-size: 1vw;
}

}


  
 