.ultrapage .bg-image-section {
  background-image: url(Ultrabg.png);
 
}




.ultrapage .left-content .down {
  display: inline-block;
  margin: 0.5rem 0rem 0rem 7.3rem;

  font-size: 1.7rem;
  font-weight: 800;

  font-family: "Montserrat";
  color: #f6f7f8;
  letter-spacing: 0.8px;
  font-stretch: expanded;
  transform: scale(1.2, 0.9);
}