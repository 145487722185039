html,body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color:#0e1b39;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: mynewfont1;
  src: url(./Components/font.otf);
}
@font-face {
  font-family: mynewfont2;
  src: url(./Components/StretchPro.otf);
}