.installers-img {
  background: url(installers.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 76.5vh;
 display: flex;
 justify-content: center;
 align-items: center;
 
}

.installers-head {
  width: 100vw;
  height: auto;
 
 
}
.installers-overlay {
  width: 100%;
  height: auto;
  background-color: rgba(14, 24, 57, 0.7); 
  pointer-events: none; 
  padding: 1vw 5.45vw 1vw 5.45vw;
 
}
.heading {
  display: inline-block;
 
  font-family: "Montserrat";
  font-size: 3.8vw;
  font-weight: 800;
  color: #ffc13c;
 
  animation: slideInRight;
  animation-duration: 1.5s;
  animation-timing-function: ease;
}
.installers-para {
  font-family: "Montserrat";
  font-size: 1.474vw;
  color: #ffff;
}
.row {
  font-size: 1.3vw;
}
.social-media-pages .social-icon:hover{
  color: #ffffff;
  scale: 1.5;

}
@media (max-width:441px){
  .heading{
    font-size: 7.5vw;
  }
  .installers-para{
    font-size: 3.5vw;
  }
  .row {
    font-size: 3vw;
  }
  .installers-img {
height: 95vh;
  }
}
@media (min-width:441px) and (max-width:766px){
  
  .heading{
    font-size:4vw;
  }
  .installers-para{
    font-size: 2vw;
  }
  .row {
    font-size: 2vw;
  }
  .installers-img {
height: 95vh;
  }
}

