.glossblackpage .bg-image-section {
  background-image: url(Glossblackbg.png);
 
}



.glossblackpage .section-content {
  padding: 3.5vw 5.5vw 3.5vw 3.5vw;
  height: auto;
  background-color: #0e1b39;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.glossblackpage .left-content {
  margin: 1vw 0 0 3.3vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.glossblackpage .right-content {
  position: absolute;
  top: 0;
  right: 0;

}

.glossblackpage .right-content img {
  margin: 6vw 8.3vw 0rem 0rem;
  width: 35vw;
  height: auto;
}




 .midle {
  display: inline-block;
  margin: 0vw 0rem 0rem 0.7vw;
  font-family: "Montserrat";
  font-weight: 800;
  color: #ffc13c;
  font-size: 3.8vw;
  letter-spacing: 0.8px;
  
}
.section-content:hover .midle{
  animation:fadeInLeft;
  animation-duration: 1.5s;
}



.glossblackpage .points {
  margin: 2vw 0 0 0;
  text-align: justify;
  font-family: "Montserrat";
  color: #f6f7f8;
 
  font-weight: 300;
}

.glossblackpage .points li {
  
  padding: 0px 3vw 0px 0px;
}



.glossblackpage .table img {
  width: 82.5vw;
  height: auto;
}


@media (max-width:441px){

.midle {
  font-size: 7.5vw;
}
.glossblackpage .points {
  margin: 2vw 0 0 -5.5vw;
}
.glossblackpage .table img {
  width: 95vw;
  height: 9vh;
  
}
.glossblackpage .right-content {
  position: relative;
  

}
.glossblackpage .right-content img {
  width: 80vw;
  height: auto;
  margin: 5vw 0vw 0rem 6vw;
  
}
.glossblackpage .table img {
  width: 82.5vw;
  height: auto;
}

}
@media (min-width:441px) and (max-width:766px){
  .glossblackpage  .bg-image-section {
    height:70vh;
    width: auto;
  }
  .midle {
    font-size: 7.5vw;
  }
  .glossblackpage .points {
    margin: 2vw 0 0 -1.5vw;
  }
  .glossblackpage .table img {
    width: 95vw;
    height: auto;
    
  }
  .glossblackpage .right-content {
    position: relative;
    
  
  }
  .glossblackpage .right-content img {
    width: 80vw;
    height: auto;
    margin: 5vw 0vw 0rem 8vw;
  }
  .glossblackpage .table img {
    width: 82.5vw;
    height: auto;
  }
}