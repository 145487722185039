.bg-image-section {
  height: 80vh;
  background-image: url(Ultimatebg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 padding: 2vw;
}


.text-container .top {
  margin: 0.0001vw 0px 0px 0rem;
  font-size: 2vw;
  padding: 0px 6px 0px 6px;
  display: inline-block;
  font-weight: 800;
  background-color: #0e1b39;
  font-family: "Montserrat";
  color: #ffc13c;
  letter-spacing: 0.8px;
  border: 2px solid #f6f7f8;
}

.text-container .bottom {
 
  padding: 0px 8px 0px 8px;
  font-size: 2vw;
  font-weight: 800;
  background-color: #0e1b39;
  font-family: "Montserrat";
  color: #f6f7f8;
  letter-spacing: 0.8px;
  border: 2px solid #f6f7f8;
}

.section-content {
  padding: 3.5vw 3.5vw 3.5vw 3.5vw;
  margin-top: -2px;
  height: auto;
  background-color: #0e1b39;
  color: white;
}
.left-content{
  margin: 0.7vw 0 0 3.3vw;
}
.left-content .up {
  display: inline-block;
  margin: 0vw 0rem 0rem 1vw;
  font-size: 1.8vw;
  font-weight: 800;

  font-family: "Montserrat";
  color: #b0ad9e;
  letter-spacing: 0.8px;
 
}
.left-content .centre {
  display: inline-block;
  margin: 0vw 0rem 0rem 0.7vw;
  font-family: "Montserrat";
  font-weight: 800;
  color: #ffc13c;
  font-size: 3.8vw;
  letter-spacing: 0.8px;

}
.section-content:hover  .centre{
  animation:fadeInLeft;
  animation-duration: 1.5s;
}

.points {
  margin: 2vw 0 0 0;
}
.points li {
  word-spacing: 0.1vw;
  line-height: 2.5vw;
  font-family: "Montserrat";
  color: #f6f7f8;
  font-size: 1.4vw;

  font-weight: 400;
}
.right-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-content img {
  width: 35vw;
  height: auto;
  margin-right: 4vw;
}
.section-content:hover .right-content img{
  animation: fadeInRight;
  animation-duration: 2s;
}
.table {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0e1b39;
  height: 35vh;
}
.table img {
  width: 83vw;
  height: auto;
  margin: 0;
}


@media (max-width:441px){
  .bg-image-section {
    height: 25vh;
  }
  .text-container .bottom {
  transform: translateY(-1vw);
  }
  .left-content .up{
    font-size: 4.5vw;
  }
  .left-content .centre {
    font-size: 7.5vw;
  }
  .points {
    margin: 2vw 0 0 -5.5vw;
    padding-right: 6vw;
  }
  .points li{
    font-size:3.5vw ;
    line-height: 6vw;
  }
  .right-content img {
    width: 80vw;
    height: auto;
  }
  .table img {
    width: 83.5vw;
    height:auto;
    margin: 0;
  }
}
@media (min-width:441px) and (max-width:766px){
  .bg-image-section {
    height: 60vh;
    width: auto;
  }
  .text-container .bottom {
  transform: translateY(-0.2vw);
  }
  .left-content .up{
    font-size: 4.5vw;
  }
  .left-content .centre {
    font-size: 7.5vw;
  }
  .points {
    margin: 2vw 0 0 -1.5vw;
  }
  .points li{
    font-size:3.5vw ;
    line-height: 6vw;
  }
  .right-content img {
    width: 80vw;
    height: auto;
  }
  .table img {
    width: 83.5vw;
    height: auto;
    margin: 0;
  }
}
@media (min-width:441px) and (max-width:614px){
  .bg-image-section {
    height: 43vh;
    width: auto;
  }}